import PropTypes from "prop-types"
import { useLocale, useT } from "react-polyglot-hooks"
import styled, { keyframes } from "styled-components"
import Icon from "components/icons/icon"
import Link from "components/Link"
import Arrow from "components/icons/arrow"
import { color } from "helpers/styled"
import { useCallback } from "react"
import { sendEvent } from "scripts/GoogleAnalytics"
import { useDispatch } from "react-redux"

const openMenu = keyframes`
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const Button = styled.button`
  display: flex;
  margin: 0;
  padding: 5px;
  border: none;
  background: transparent;
  color: ${color("deepOrange")};
  transform: translateX(10px) rotate(90deg);
  z-index: 1;
`

const MenuArrow = styled(Icon)`
  width: 8px;
  height: 8px;
`

export const HoverMenu = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  min-width: 225px;
  padding: 25px;
  background-color: ${color("white")};
  border-radius: 12px;
  box-shadow: 0 4px 12px 0 rgba(195, 205, 224, 0.4);
  animation: ${openMenu} 0.2s;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 113%;
    height: 113%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  color: ${color("darkBlue")};

  > * + *:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: ${color("paleGray")};
  }
`

export default function Submenu({ page }) {
  const t = useT()
  const locale = useLocale()
  const dispatch = useDispatch()

  const handleClick = useCallback(
    item => {
      sendEvent("Menu", { event_category: page, event_label: item })
    },
    [page, dispatch]
  )

  return (
    <>
      <Button>
        <MenuArrow Icon={Arrow} />
      </Button>
      <HoverMenu>
        <List>
          <li>
            <Link page="stock">
              {/* eslint-disable-next-line */}
              <a onClick={() => handleClick("stock")}>{t("common.menu.sub.stock")}</a>
            </Link>
          </li>

          {["es", "pt"].includes(locale) && (
            <li>
              <Link page="pre-owned">
                {/* eslint-disable-next-line */}
                <a onClick={() => handleClick("pre-owned")}>{t("common.menu.sub.pre-owned")}</a>
              </Link>
            </li>
          )}
          <li>
            <Link page="commercial">
              {/* eslint-disable-next-line */}
              <a onClick={() => handleClick("commercial")}>{t("common.menu.sub.commercial")}</a>
            </Link>
          </li>
        </List>
      </HoverMenu>
    </>
  )
}

Submenu.propTypes = {
  page: PropTypes.string.isRequired
}
