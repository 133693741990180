import { useT } from "react-polyglot-hooks"
import styled from "styled-components"
import Icon from "../../../icons/icon"
import Phone from "../../../icons/phone"

const TopPhone = styled.a`
  padding: 0;
  font-size: 0;
  background: none;
  border: none;
  margin-left: auto;
  color: ${({ theme, white }) => (white ? "white" : theme.colors.deepOrange)};
  @media ${({ theme }) => theme.breakpoints.lg} {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme, white }) => (white ? theme.colors.deepOrange : "white")};
    border-radius: 24px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    border: none;
    padding: 9px 15px;
    white-space: nowrap;
    svg {
      margin-right: 15px;
    }
  }
`

export default function TopPhoneButton(props) {
  const t = useT()
  return (
    <TopPhone href={t("common.phone.href")} {...props}>
      <Icon Icon={Phone} />
      {t("common.phone.text")}
    </TopPhone>
  )
}
