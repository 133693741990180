import { useState } from "react"
import styled from "styled-components"
import * as PropTypes from "prop-types"
import dynamic from "next/dynamic"
import { sendEvent } from "scripts/GoogleAnalytics"
import { Container } from "../../styled/Grid"
import Link from "../../Link"
import Menu from "./Menu"
import { PurpleLogo, WhiteLogo } from "../../icons/logo"
import Burger from "../../icons/burger"
import Icon from "../../icons/icon"
import CallButton from "./CallButton"

const SideMenu = dynamic(() => import("./SideMenu"))

const NavBar = styled.nav`
  height: 100px;
  padding: 25px 0;
  background: transparent;
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -65px;
  @media ${({ theme }) => theme.breakpoints.lg} {
    position: static;
    margin: 0;
  }
`

const MenuButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: none;
  }
`

const BurguerIcon = styled(Icon)`
  ${({ white, theme }) =>
    white
      ? "color: white;"
      : `
    color: ${theme.colors.deepOrange};
  @media ${theme.breakpoints.sm} {
    color: ${theme.colors.darkBlue};
  }
  `}
`

export default function TopBar(props) {
  const { white, page, floating, grayCta } = props
  const [isOpen, setIsOpen] = useState(false)

  const onClickLogo = () => {
    sendEvent("logo", { event_category: page, event_label: "logo click" })
  }

  const onClickCTA = float => {
    sendEvent("incoming call", { event_category: page, event_label: `incoming call ${float ? "floating" : "header"}` })
  }

  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <Container>
      <NavBar>
        <MenuButton onClick={toggleMenu}>
          <BurguerIcon Icon={Burger} white={white} home={page === "home"} />
        </MenuButton>
        <Logo>
          <Link page="stock">
            {/* eslint-disable-next-line */}
            <a onClick={onClickLogo} role="button" tabIndex={0}>
              <LogoImage white={white} />
            </a>
          </Link>
        </Logo>
        <Menu white={white} page={page} />
        <CallButton floating={floating} white={white} grayCta={grayCta} handleClick={onClickCTA} />
      </NavBar>
      {isOpen && <SideMenu open={isOpen} onClose={() => setIsOpen(false)} page={page} />}
    </Container>
  )
}

function LogoImage({ white }) {
  return white ? <WhiteLogo /> : <PurpleLogo />
}

LogoImage.propTypes = { white: PropTypes.bool.isRequired }

TopBar.propTypes = {
  page: PropTypes.string.isRequired,
  white: PropTypes.bool.isRequired,
  grayCta: PropTypes.bool.isRequired,
  floating: PropTypes.bool.isRequired
}
