import { useT } from "react-polyglot-hooks"
import styled from "styled-components"
import Icon from "../../../icons/icon"
import Phone from "../../../icons/phone"

const TelButton = styled.a`
  background: ${({ theme, grayCta }) => theme.colors[grayCta ? "paleGray" : "white"]};
  color: ${({ theme }) => theme.colors.deepOrange};
  border-radius: 24px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  border: none;
  padding: 9px 15px;
  white-space: nowrap;
  ${({ border, theme }) => (border ? `border: 1px solid ${theme.colors.deepOrange};` : "")}
  svg {
    font-size: 24px;
    margin-right: 15px;
  }
`

export default function PhoneButton(props) {
  const t = useT()
  return (
    <TelButton href={t("common.phone.href")} {...props}>
      <Icon Icon={Phone} />
      {t("common.phone.text")}
    </TelButton>
  )
}
