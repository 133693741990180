import * as PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useT } from "react-polyglot-hooks"
import { sendEvent } from "scripts/GoogleAnalytics"
import Link from "components/Link"
import { emptyCarsFilter } from "redux/actions"
import { useDispatch } from "react-redux"
import { color, mq } from "helpers/styled"
import { useCallback } from "react"
import { HoverMenu } from "./Submenu"

const MenuList = styled.ul`
  display: none;
  justify-content: space-between;
  list-style: none;
  padding: 0;

  @media ${mq("lg")} {
    display: flex;
  }
`

const MenuItem = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  text-align: center;

  &:not(:hover):not(:focus):not(:focus-within) ${HoverMenu} {
    display: none;
  }
`

const MenuLink = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  line-height: 13px;
  padding: 6px 0;
  letter-spacing: 0.5px;
  z-index: 1;
  ${({ white }) => (white ? "color: white;" : null)}

  ${({ selected, white }) =>
    selected &&
    css`
      border-bottom: 2px solid ${color(white ? "white" : "darkBlue")};
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      padding-right: 18px;
      margin-right: -23px;
    `}
`

const mainMenu = ["stock", "commercial", "pre-owned"]

export default function Menu({ white, page }) {
  const t = useT()
  const dispatch = useDispatch()

  const currentPage = page

  const handleClick = useCallback(
    item => {
      if (item === "stock" && page === "stock") dispatch(emptyCarsFilter())
      sendEvent("Menu", { event_category: page, event_label: item })
    },
    [page, dispatch]
  )

  return (
    <MenuList>
      {mainMenu.map(item => (
        <MenuItem key={item}>
          <Link page={item}>
            <MenuLink
              selected={item === currentPage}
              white={white}
              onClick={() => handleClick(item)}>
              {t(`common.menu.${item}`)}
            </MenuLink>
          </Link>
        </MenuItem>
      ))}
    </MenuList>
  )
}

Menu.propTypes = {
  white: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired
}
