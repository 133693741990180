import styled from "styled-components"
import ReactDOMServer from "react-dom/server"

export const WhiteLogo = styled(Logo)`
  color: white;
`

export const SilverLogo = styled(Logo)`
  color: ${({ theme }) => theme.colors.silver};
  width: 100px;
`

export function Logo({ ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="130" height="32" viewBox="0 0 130 32" {...rest}>
        <path fill="currentColor" fillRule="evenodd" d="m47.05,16c0-8.5-6.78-15.41-15.23-15.62-.11-.03-.23-.04-.35-.04h-13.08c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h3.74c-.83.61-1.6,1.31-2.29,2.07h-6.61s0,0,0,0c-.86,0-1.55.69-1.55,1.55,0,.85.69,1.55,1.55,1.55h4.43c-1.19,2.21-1.86,4.73-1.86,7.4,0,3.05.88,5.9,2.39,8.31h-2.08c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h4.64c.44.41.9.8,1.38,1.16h-8.38c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h17.55c.11,0,.22-.01.33-.04,8.54-.11,15.42-7.06,15.42-15.62Zm-4.62-3.91h-22.01c1.62-4.54,5.92-7.81,11-7.81s9.38,3.28,11,7.81Zm-22.73,3.91c5.34,0,9.66,5.14,9.75,11.52-5.53-.95-9.75-5.73-9.75-11.52Zm11.72,1.95c-1.08,0-1.95-.87-1.95-1.95s.87-1.95,1.95-1.95,1.95.87,1.95,1.95-.87,1.95-1.95,1.95Zm1.97,9.57c.09-6.38,4.41-11.52,9.75-11.52,0,5.79-4.23,10.58-9.75,11.52Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m63.72,15.09h-2.96l-1.83-4.37h-1.88v4.37h-2.77V3.52h5.51c1.98,0,3.6,1.62,3.6,3.6,0,1.26-.66,2.37-1.64,3.03l1.96,4.94Zm-4.03-9.18v-.02h-2.72v2.45h2.72c.61-.07,1.08-.59,1.08-1.24s-.47-1.15-1.08-1.19Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m72.16,15.09h-7.87V3.52h7.72v2.5h-4.76v2.03h4.01v2.5h-4.01v2.03h4.91v2.5Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m82.37,3.52v11.57h-2.95l-3.4-6.2v6.2h-2.96V3.52h2.96l3.4,6.2V3.52h2.95Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m92,6.47h-2.96v8.62h-2.96V6.47h-2.96v-2.95h8.88v2.95Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m95.76,15.09h-2.95V3.52h2.95v11.57Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m106.05,3.52v11.57h-2.94l-3.4-6.2v6.2h-2.96V3.52h2.96l3.4,6.2V3.52h2.94Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m118.32,9.36c0,3.22-2.62,5.84-5.86,5.84s-5.84-2.62-5.84-5.84,2.62-5.86,5.84-5.86c1.64,0,3.11.67,4.16,1.73l-2.63,1.51c-.44-.26-.97-.41-1.52-.41-1.65,0-3.01,1.36-3.01,3.03s1.36,3.01,3.01,3.01c1.18,0,2.19-.67,2.7-1.65h-3.63v-2.72h6.63c.1.44.16.88.16,1.36Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m57.23,19.09v2.03h4.09v2.5h-4.09v4.53h-2.95v-11.57h8.05v2.5h-5.1Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m65.93,28.15h-2.95v-11.57h2.95v11.57Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m76.22,16.58v11.57h-2.95l-3.4-6.2v6.2h-2.96v-11.57h2.96l3.4,6.2v-6.2h2.95Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m85.53,17.92c1.95,1.77,2.47,4.7,1.26,7.04-1.93,3.75-6.14,3.16-9.67,3.16v-11.7h2.95c2,0,3.88.08,5.46,1.51Zm-3.85,1.33h-1.62v6.04c1.88,0,3.94-.13,4.47-2.31.46-1.85-.97-3.68-2.85-3.73Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m95.89,28.15h-7.87v-11.57h7.72v2.5h-4.76v2.03h4.01v2.5h-4.01v2.03h4.91v2.5Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m106.23,28.15h-2.96l-1.83-4.37h-1.88v4.37h-2.77v-11.57h5.51c1.98,0,3.6,1.62,3.6,3.6,0,1.26-.66,2.37-1.64,3.03l1.96,4.94Zm-4.02-9.18v-.02h-2.72v2.45h2.72c.61-.07,1.08-.59,1.08-1.24s-.47-1.14-1.08-1.19Z"/>
        <path fill="currentColor" fillRule="evenodd" d="m112.09,20.25v-.21c0-.64-.62-1.15-1.37-1.15s-1.42.52-1.42,1.15c0,.29.13.54.43.7h.02c.29.21.72.36,1.1.46.18.03.34.08.54.11.79.2,1.62.39,2.28.9.02.03.05.05.06.07l.2.16h-.02c.67.67,1.03,1.52,1.03,2.41,0,.98-.44,1.9-1.24,2.6-.8.67-1.85,1.05-2.98,1.05s-2.16-.38-2.96-1.05c-.8-.7-1.26-1.62-1.26-2.6v-.21h2.86v.21c0,.62.61,1.14,1.36,1.14s1.42-.52,1.42-1.14c0-.29-.13-.54-.42-.7l-.02-.02c-.3-.23-.72-.34-1.1-.44-.18-.05-.34-.08-.54-.13-.79-.2-1.7-.39-2.34-.97-.02-.02-.03-.03-.07-.05-.74-.67-1.16-1.55-1.16-2.5s.46-1.9,1.26-2.6c.8-.67,1.85-1.05,2.96-1.05s2.18.38,2.98,1.05c.8.7,1.24,1.62,1.24,2.6v.21h-2.83Z"/>
    </svg>
  )
}

function PurpleLogoSVG() {
  return (
    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="130" height="32" viewBox="0 0 130 32">
        <defs>
            <linearGradient id="44ld8snvza">
                <stop stopColor="#1a2f4d" />
            </linearGradient>
            <linearGradient id="greenLogo">
                <stop stopColor="#4aab84" />
            </linearGradient>
        </defs>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m47.05,16c0-8.5-6.78-15.41-15.23-15.62-.11-.03-.23-.04-.35-.04h-13.08c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h3.74c-.83.61-1.6,1.31-2.29,2.07h-6.61s0,0,0,0c-.86,0-1.55.69-1.55,1.55,0,.85.69,1.55,1.55,1.55h4.43c-1.19,2.21-1.86,4.73-1.86,7.4,0,3.05.88,5.9,2.39,8.31h-2.08c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h4.64c.44.41.9.8,1.38,1.16h-8.38c-.86,0-1.55.69-1.55,1.55s.69,1.55,1.55,1.55h17.55c.11,0,.22-.01.33-.04,8.54-.11,15.42-7.06,15.42-15.62Zm-4.62-3.91h-22.01c1.62-4.54,5.92-7.81,11-7.81s9.38,3.28,11,7.81Zm-22.73,3.91c5.34,0,9.66,5.14,9.75,11.52-5.53-.95-9.75-5.73-9.75-11.52Zm11.72,1.95c-1.08,0-1.95-.87-1.95-1.95s.87-1.95,1.95-1.95,1.95.87,1.95,1.95-.87,1.95-1.95,1.95Zm1.97,9.57c.09-6.38,4.41-11.52,9.75-11.52,0,5.79-4.23,10.58-9.75,11.52Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m63.72,15.09h-2.96l-1.83-4.37h-1.88v4.37h-2.77V3.52h5.51c1.98,0,3.6,1.62,3.6,3.6,0,1.26-.66,2.37-1.64,3.03l1.96,4.94Zm-4.03-9.18v-.02h-2.72v2.45h2.72c.61-.07,1.08-.59,1.08-1.24s-.47-1.15-1.08-1.19Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m72.16,15.09h-7.87V3.52h7.72v2.5h-4.76v2.03h4.01v2.5h-4.01v2.03h4.91v2.5Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m82.37,3.52v11.57h-2.95l-3.4-6.2v6.2h-2.96V3.52h2.96l3.4,6.2V3.52h2.95Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m92,6.47h-2.96v8.62h-2.96V6.47h-2.96v-2.95h8.88v2.95Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m95.76,15.09h-2.95V3.52h2.95v11.57Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m106.05,3.52v11.57h-2.94l-3.4-6.2v6.2h-2.96V3.52h2.96l3.4,6.2V3.52h2.94Z"/>
        <path fill="url(#44ld8snvza)" fillRule="evenodd" d="m118.32,9.36c0,3.22-2.62,5.84-5.86,5.84s-5.84-2.62-5.84-5.84,2.62-5.86,5.84-5.86c1.64,0,3.11.67,4.16,1.73l-2.63,1.51c-.44-.26-.97-.41-1.52-.41-1.65,0-3.01,1.36-3.01,3.03s1.36,3.01,3.01,3.01c1.18,0,2.19-.67,2.7-1.65h-3.63v-2.72h6.63c.1.44.16.88.16,1.36Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m57.23,19.09v2.03h4.09v2.5h-4.09v4.53h-2.95v-11.57h8.05v2.5h-5.1Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m65.93,28.15h-2.95v-11.57h2.95v11.57Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m76.22,16.58v11.57h-2.95l-3.4-6.2v6.2h-2.96v-11.57h2.96l3.4,6.2v-6.2h2.95Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m85.53,17.92c1.95,1.77,2.47,4.7,1.26,7.04-1.93,3.75-6.14,3.16-9.67,3.16v-11.7h2.95c2,0,3.88.08,5.46,1.51Zm-3.85,1.33h-1.62v6.04c1.88,0,3.94-.13,4.47-2.31.46-1.85-.97-3.68-2.85-3.73Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m95.89,28.15h-7.87v-11.57h7.72v2.5h-4.76v2.03h4.01v2.5h-4.01v2.03h4.91v2.5Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m106.23,28.15h-2.96l-1.83-4.37h-1.88v4.37h-2.77v-11.57h5.51c1.98,0,3.6,1.62,3.6,3.6,0,1.26-.66,2.37-1.64,3.03l1.96,4.94Zm-4.02-9.18v-.02h-2.72v2.45h2.72c.61-.07,1.08-.59,1.08-1.24s-.47-1.14-1.08-1.19Z"/>
        <path fill="url(#greenLogo)" fillRule="evenodd" d="m112.09,20.25v-.21c0-.64-.62-1.15-1.37-1.15s-1.42.52-1.42,1.15c0,.29.13.54.43.7h.02c.29.21.72.36,1.1.46.18.03.34.08.54.11.79.2,1.62.39,2.28.9.02.03.05.05.06.07l.2.16h-.02c.67.67,1.03,1.52,1.03,2.41,0,.98-.44,1.9-1.24,2.6-.8.67-1.85,1.05-2.98,1.05s-2.16-.38-2.96-1.05c-.8-.7-1.26-1.62-1.26-2.6v-.21h2.86v.21c0,.62.61,1.14,1.36,1.14s1.42-.52,1.42-1.14c0-.29-.13-.54-.42-.7l-.02-.02c-.3-.23-.72-.34-1.1-.44-.18-.05-.34-.08-.54-.13-.79-.2-1.7-.39-2.34-.97-.02-.02-.03-.03-.07-.05-.74-.67-1.16-1.55-1.16-2.5s.46-1.9,1.26-2.6c.8-.67,1.85-1.05,2.96-1.05s2.18.38,2.98,1.05c.8.7,1.24,1.62,1.24,2.6v.21h-2.83Z"/>
    </svg>
  )
}

export function PurpleLogo() {
  return (
    <img
      alt="Renting Finders"
      src={`data:image/svg+xml;utf8,${encodeURIComponent(ReactDOMServer.renderToStaticMarkup(PurpleLogoSVG()))}`}
    />
  )
}
